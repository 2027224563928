import React from "react"
import styled from "styled-components"

const StyledSVG = styled.svg`
  height: ${props => props.size || "200px"};
  width: ${props => props.size || "200px"};
  .cls-1 {
    fill: #bc672c;
  }
  .cls-2 {
    fill: #63341c;
  }
  .cls-3 {
    fill: #f1b187;
  }
  .cls-4 {
    fill: #1f120b;
  }
  .cls-5 {
    fill: #56311d;
  }
  .cls-6 {
    fill: #af5c27;
  }
`

const Logo = ({ size }) => (
  <StyledSVG
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 142.71 114.57"
    size={size}
  >
    <path
      className="cls-1"
      d="M121.07,97.88a4.29,4.29,0,1,1,8.58,0,4.29,4.29,0,1,1-8.58,0Z"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-1"
      d="M54.55,97.54a4.29,4.29,0,1,1,8.58,0,4.29,4.29,0,1,1-8.58,0Z"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-1"
      d="M109.07,86.51a17.79,17.79,0,0,0-3.79-2.59,15,15,0,0,0,5.36-11.28,13.73,13.73,0,0,0-4.49-10.35,16.6,16.6,0,0,0-11.32-4.11,16.37,16.37,0,0,0-6.17,1.24,44.93,44.93,0,0,1,6.66,4.37c5.74.19,9.72,3.77,9.72,8.85a9.6,9.6,0,0,1-2.44,6.15,8.82,8.82,0,0,1-6.11,2.91V74.09A28.47,28.47,0,0,1,90.9,78v10c-4.94,1.38-8.82,6.61-8.82,12.43a15.47,15.47,0,0,0,3.65,10.49c1.89,2.08,5.45,4.62,11.6,4.62a18.15,18.15,0,0,0,6.93-1.29,15.69,15.69,0,0,0,5.45-3.61,17.07,17.07,0,0,0,4.67-12A16.19,16.19,0,0,0,109.07,86.51ZM90.9,108a6.14,6.14,0,0,1-1-.93,9.9,9.9,0,0,1-2.26-6.74,7.91,7.91,0,0,1,1.94-5.18A6.09,6.09,0,0,1,90.9,94Zm14.84-1.39a11.41,11.41,0,0,1-8.41,3.24h-.84V87.43a13.12,13.12,0,0,1,8.83,3.23,10.65,10.65,0,0,1,3.47,7.91A11.4,11.4,0,0,1,105.74,106.65Z"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-1"
      d="M72.56,12.32a20,20,0,0,0-6,1.15s-.74-6.34-2.64-6.34S59.4,14.7,60.15,17.91c0,0-3.08-8.39.22-13.5s9.43-4,11.08-.5c0,0,.12.19.54,1.07.25.55.57,1.49.57,1.49"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-2"
      d="M72.29,12.32a19.94,19.94,0,0,1,6,1.15s.75-6.34,2.64-6.34,3.74,7.57,3,10.78c0,0,3.84-8.39.54-13.5s-9.43-4-11.08-.5c0,0-.12.19-.53,1.07-.26.55-.58,1.49-.58,1.49"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-2"
      d="M61.33,18.15a1.24,1.24,0,1,1-1.24-1.24A1.25,1.25,0,0,1,61.33,18.15Z"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-1"
      d="M85,18.15a1.24,1.24,0,1,1-1.24-1.24A1.24,1.24,0,0,1,85,18.15Z"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-3"
      d="M77.75,14.31a13.4,13.4,0,0,0-5.46-1.1,20.47,20.47,0,0,0-5.7,1.1s.36,5,3.5,7.94a4.71,4.71,0,0,0,2.2,1.17,4.77,4.77,0,0,0,2.54-1.51A13,13,0,0,0,77.75,14.31Z"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-2"
      d="M72.29,15.36a5,5,0,0,0-5.41.65,4.33,4.33,0,0,0,5.41.83A4.34,4.34,0,0,0,77.7,16,5,5,0,0,0,72.29,15.36Zm-4.11.78c2.19-1.28,3.22,0,3.22,0C69.24,17.34,68.18,16.14,68.18,16.14Zm4.86,0s1-1.28,3.22,0C76.26,16.14,75.2,17.34,73,16.14Z"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-2"
      d="M69.53,22.84l-9.26,3.81h8.34l-2.72,6.6,6.46-4.84v-4S70.09,23.86,69.53,22.84Z"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-1"
      d="M75.15,22.87l9.26,3.81H76.08l2.71,6.6-6.46-4.84v-4S74.59,23.89,75.15,22.87Z"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-1"
      d="M68.76,26.65H60.43s-3.91,1.42-4,11l-.13,4.15s-7.87,1-12,3.1l-.49,2.48,12.27-1s6.26.75,7.93-13.07c0,0,2.73,17.78,5.45,17.91h2.85V28.41L66.1,33.07"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-2"
      d="M75.89,26.65h8.33s3.91,1.42,4,11l.13,4.15s7.87,1,12,3.1l.49,2.48-12.27-1s-6.25.75-7.93-13.07c0,0-2.73,17.78-5.45,17.91H72.33V28.41l6.25,4.71"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-3"
      d="M42.17,44.43A7.32,7.32,0,0,0,40,44c-.93,0-2.88.93-2,2s.19,1.77.56,2.32a3.11,3.11,0,0,0,.84,1c.46.37.17.74.59,1s2.35-1.55,2.85-2.51l.8-3"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-1"
      d="M68.63,46.54s-5.63,7.58-5.74,11l6.2-2.43L72,58.72,72.89,47"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-2"
      d="M76.59,46.14s5.63,8,5.74,11.32L75.77,55,72,58.72l.36-12.12"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-2"
      d="M63.71,57.88l5-1.84,3.44,3.82.3,47H69.1l-2-13.75s-1.21-11.22-3-19.57c-2-9.66-.62-14.32-.62-14.32"
      transform="translate(-1.14 -0.91)"
    />
    <polyline
      className="cls-4"
      points="103.15 40.98 102.26 43.82 105.47 53.23 106.36 50.53"
    />
    <path
      className="cls-1"
      d="M81.54,58.22s13.39,5,15.24,9.64-14.19,13.68-19.89,16V79.6l12.46-10s-12.46-2.91-17.2-5v-4.8L75.63,56"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-2"
      d="M80.66,76.45s-2.21,1.64-3,1-3.43,1.7-3,4a7.18,7.18,0,0,1-.29,4.74c-.59,1.7-1.45,6,4.37,5.93,0,0,3.8-.48,3-2.83s-3.21-2.1-3-.8c0,0,.59-1.11,1.43,0s-.11,2.31-1.55,1.52-3.25-1.75-.28-5,3.44-3,3.44-3"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-1"
      d="M72.72,105.21s.27,3.26,1,4-1.67,3.56-4.39,3.36a9.39,9.39,0,0,0-5.38.71c-1.89.75-6.74,2-7.11-3.81,0,0,.26-3.81,3-3.18s2.65,3,1.14,2.87c0,0,1.23-.68-.08-1.43s-2.62.32-1.61,1.68,2.26,3.07,5.75-.17,3.1-3.69,3.1-3.69"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-2"
      d="M54.16,48.5c-5.55-1.19-11.4-3.1-11.4-3.1h0c-9.88-3.18-20.65-6.48-20.65-6.48S11.62,31.39,2.92,31c0,0-2.77.4-1.39,3.36,0,0,2.18,11.74,17.81,7a7.6,7.6,0,0,0,2.52-1.52l23,7.47s6,2.66,8.55,3.93,5.6,2.49,6.9,0C60.27,51.19,59.71,49.69,54.16,48.5Z"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-1"
      d="M19.93,38.31S5.59,36.83,3.51,32.48c0,0-1.64-1.49,1.31-2.48s7.3.69,8.09-1.93c0,0,9.5,6.18,8.31,8.66C21.22,36.73,21.76,38.55,19.93,38.31Z"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-2"
      d="M126.66,29.13"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-2"
      d="M117.5,34.43s22.05-13.31,22.27-12.58,4.09,9.77,4.09,9.77L107.78,52.07l-3.36-10"
      transform="translate(-1.14 -0.91)"
    />
    <polygon
      className="cls-4"
      points="116.91 34.46 119.54 40.61 119.54 40.61 119.95 41.5 118.89 43.16 115.24 34.19 116.91 34.46"
    />
    <polygon
      className="cls-4"
      points="109.46 39.39 112.09 45.54 112.09 45.54 112.51 46.43 111.44 48.1 107.79 39.12 109.46 39.39"
    />
    <polygon
      className="cls-4"
      points="142.67 30.82 106.64 51.16 105.71 53.99 141.39 33.56 142.67 30.82"
    />
    <polygon
      className="cls-4"
      points="131.7 26.34 134.33 32.49 134.33 32.49 134.75 33.38 133.68 35.04 130.03 26.07 131.7 26.34"
    />
    <polygon
      className="cls-4"
      points="124.26 30.55 126.89 36.71 126.89 36.71 127.31 37.59 126.24 39.26 122.59 30.28 124.26 30.55"
    />
    <path
      className="cls-5"
      d="M132.84,27.25l5.55-2.57L142,31.44a1,1,0,0,0,.21-.34c.09-.24-3.6-6.93-3.6-6.93l-.27-.58L131.37,27"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-5"
      d="M125.36,31.49l5.55-2.57,3.55,6.77a1.15,1.15,0,0,0,.21-.35c.1-.23-3.6-6.93-3.6-6.93l-.27-.58-6.92,3.43"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-5"
      d="M117.91,35.57,123.46,33,127,39.76a.93.93,0,0,0,.22-.34c.09-.24-3.6-6.93-3.6-6.93l-.28-.58-6.91,3.42"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-5"
      d="M110.3,40.48l5.56-2.57,3.55,6.76a1,1,0,0,0,.21-.34c.1-.24-3.6-6.94-3.6-6.94l-.27-.58-6.92,3.43"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-3"
      d="M103.14,44.72a7.82,7.82,0,0,1,2.24-.48c1,0,3,1,2.15,2s-.2,1.85-.59,2.44a3.37,3.37,0,0,1-.87,1.07c-.49.39-.18.78-.63,1.07s-2.46-1.62-3-2.63l-.84-3.12"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-3"
      d="M40.37,44s-2.19,3.08-1.45,3.37,2.15-1.49,2.93-2.25.59-.32.59-.32"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-1"
      d="M45.87,91.1v18.49a4.22,4.22,0,0,0,1,2.49v.33H39v-.33a4.52,4.52,0,0,0,1-2.49V72.39a4.39,4.39,0,0,0-1-2.54v-.33h7.91v.33a4.39,4.39,0,0,0-1,2.49V85.51c5.22-.37,8.09-4.9,8.09-10s-3.56-9.8-10.4-9.8c-4.67,0-9.29,2.87-9.29,7.67,0,2.22.56,3.38,2,4.39v.37c-3.56,2.36-8,.42-8-4.9,0-6.19,5.73-13.54,15.3-13.54,10,0,16.4,6.66,16.4,15.81C60,83,55.16,90.73,45.87,91.1Z"
      transform="translate(-1.14 -0.91)"
    />
    <path
      className="cls-6"
      d="M7.19,31.4a16.54,16.54,0,0,0,11.69,4.12l.69-.06a.69.69,0,0,0,0-.88c-.38-.43,1.94,2.44-2.31,1.63S7.88,34,7.19,31.4Z"
      transform="translate(-1.14 -0.91)"
    />
  </StyledSVG>
)

export default Logo
